import { h } from 'preact';
import Router from 'preact-router';
import { Player } from "@lottiefiles/react-lottie-player";
import otpanim from "../animations/14991-otp.json";
import erroranim from "../animations/97562-error.json";
import successanim from "../animations/95833-success.json";
import useAxios from 'axios-hooks';
import tw, { styled } from 'twin.macro';


const Container = styled.div(() => [
  tw`flex flex-col items-center justify-center h-screen`,
  // tw`bg-indigo-800`
]);

function Success({ matches }) {
  const [{ loading, error }] = useAxios(
    {
      method: 'POST',
      url: 'https://api.dostow.com/v1/store/otpresponse',
      headers: {
        "x-dostow-group-access-key": "6bfea77f-2a48-45ae-9b5c-17778787f664"
      },
      data: {
        h: matches.h
      }
    }
  );
  return (
    <Container>
      <Player
        autoplay
        keepLastFrame
        speed="2"
        loop={loading}
        src={error ? erroranim : loading ? otpanim : successanim}
        style={{ height: "400px", width: "400px" }}
      />
      <h1>{loading ? '' : error ? error.response.data.msg : "Account Verified"}</h1>
    </Container>
  );
}


export default function App() {
  return (<Router>
    <Success default />
  </Router>);
}